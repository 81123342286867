import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { H1Styles, CarouselPartner } from '../../styles/GlobalStyles';
import logUnimedCg from '../../assets/image/ucdb.png';
import logoUcdb from '../../assets/image/unimed-campo-grande.png';
import logoUniderp from '../../assets/image/uniderp.png';

export function PartnersComponent() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [logUnimedCg, logoUcdb, logoUniderp];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <Row className="mb-5">
      <Col xl={9} className="mx-auto mt-5 mt-8">
        <Row>
          <Col>
            <H1Styles className="mb-4">Parceiros</H1Styles>
            <Row className="d-flex justify-content-center">
              <Col lg={8} className="d-flex justify-content-center" style={{ width: '400px'}}>
                
                 {/* Botão de anterior */}
                <div>
                  <button className="btn btn-default control-btn" style={{ fontSize: '20px' , marginTop: '30px'}} onClick={handlePrev}>
                    &#10094;
                  </button>
                </div>

                <CarouselPartner>
                  <div
                    className="carousel-images"
                    style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                  >
                    {images.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt={`Parceiro ${index + 1}`}
                        className="carousel-image"
                      />
                    ))}
                  </div>
                </CarouselPartner>

                {/* Botão de próximo */}
                <div>
                  <button className="btn btn-default control-btn" style={{ fontSize: '20px', marginTop: '30px' }} onClick={handleNext}>
                    &#10095;
                  </button>
                </div>

              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
