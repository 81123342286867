import { Card, Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const PageTitle = styled.h1`
  width: auto;
  color: #fff;

  font-weight: 600;
  font-size: 40px;
  line-height: 53px;

  @media screen and (max-width: 600px) {
    font-size: 28px;
    line-height: 33px;
    max-width: 80%;
    margin: 0;
  }

  @media screen and (max-width: 1210px) {
    font-size: 32px;
  }
`;

export const PageDescription = styled.p`
  color: #fff;

  font-weight: 400;
  font-size: 20px;
  margin-bottom: 0px;
  line-height: 27px;
  letter-spacing: 3px;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media screen and (min-width: 601px) and (max-width: 1210px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const H1Styles = styled.h1`
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 0px;
  color: #424242;

  @media screen and (max-width: 600px) {
    &.headlineContractH1 {
      font-size: 22px;
      line-height: 33px;
      text-align: left;
    }
  }

  @media screen and (max-width: 1210px) {
    font-size: 28px;
  }
`;
export const TitleStyles = styled.h1`
  font-weight: 600;
  font-size: 40px;
  color: #424242;
  text-align: center;
  line-height: 53px;

  @media screen and (max-width: 1210px) {
    font-size: 28px;
    line-height: 34px;
  }
`;

export const H1WhiteStyles = styled.h1`
  font-style: bold;
  font-weight: 600;
  font-size: 40px;
  color: #fff;
  @media screen and (max-width: 1210px) {
    font-size: 28px;
  }
`;

export const H2Styles = styled.h2`
  font-style: bold;
  font-weight: 600;
  font-size: 2.4vmin;
  color: #424242;
  @media screen and (max-width: 1210px) {
    font-size: 22px;
  }
`;

export const H2WhiteStyles = styled.h2`
  font-style: bold;
  font-weight: 600;
  font-size: 2.4vmin;
  color: #fff;

  @media screen and (max-width: 1210px) {
    font-size: 22px;
  }
`;

export const H3Styles = styled.h3`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 8px;
  letter-spacing: 2.3px;
  color: #424242;
`;

export const H3WhiteStyles = styled.h3`
  color: #fff;
  font-weight: 500;
  font-size: 25px;
  line-height: 32px;

  @media screen and (max-width: 600px) {
    font-size: 22px;
    line-height: 31px;
  }
`;

export const H3GreenStyles = styled.h3`
  font-weight: 500;
  font-size: 20px;
  color: #72b81f;

  @media screen and (max-width: 1210px) {
    font-size: 18px;
  }
`;

export const PBlackStyles = styled.p`
  text-align: justify;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #424242;

  &.who-we-are-p {
    margin-top: 35px;
  }

  & img {
    max-width: 100%;
  }

   @media screen and (max-width: 399px) {
    font-size: 1rem;
    line-height: 23px;
    margin: 24px 15px 0px 0px !important;    
  }

  @media screen and (max-width: 600px) {
    font-size: 1rem;
    line-height: 23px;

    &.who-we-are-p {
      margin: 24px 25px !important;
    }
  }

  @media screen and (min-width: 600px) and (max-width: 1210px) {
    font-size: 18px;
    line-height: 25px;

    &.who-we-are-p {
      margin: 24px 0 0 !important ;
    }
  }
`;

export const PGreyStyles = styled.p`
  text-align: justify;

  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #787878;
`;
export const TitleBlackStyles = styled.text`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000;
`;
export const PWhiteStyles = styled.p`
  text-align: justify;

  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  color: #fff;

  @media screen and (max-width: 600px) {
    font-size: 1rem;
    line-height: 25px;
  }
`;

export const EmptyRow = styled(Row)<{ height: string; mobileHeight?: string }>`
  height: ${(props) => props.height};

  @media screen and (max-width: 1210px) {
    height: ${(props) => props.mobileHeight ?? props.height};
  }
`;
export const SubTitleStyles = styled.h1`
  text-align: justify;

  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #424242;

  @media screen and (max-width: 1210px) {
    font-size: 16px;
  }
`;
export const SeeMoreButton = styled.a`
  text-decoration: none;
  box-sizing: border-box;
  width: 220px;

  color: #72b81f;
  font-weight: 600;
  padding: 12px 24px;

  /* Auto layout */
  display: flex;
  align-items: center;

  background: #fff;
  border: 1px solid #72b81f;
  border-radius: 100px;

  &:hover {
    background-color: #72b81f;
    color: #fff;
    transition: all 0.4s;
  }

  span {
    font-size: 20px;
    line-height: 26px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  @media screen and (min-width: 601px) and (max-width: 1210px) {
    width: 100%;
  }
`;

export const SecondaryButton = styled.button`
  border: none;
  border-radius: 100px;
  padding: 12px 32px;
  height: 50px;

  background: #72b81f;
  color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.3s;

  &:hover {
    font-weight: 600;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
  }
  &:disabled {
    background: #acdd70;
    font-weight: 500;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }

  @media screen and (max-width: 1210px) {
    width: 100%;
  }
`;

export const TerciaryGreenButton = styled.button`
  border-radius: 100px;
  padding: 12px 32px;
  background: #fff;
  border: 1px solid #72b81f;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  height: 50px;

  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #72b81f;

  transition: all 0.3s;
  &:hover {
    background: #72b81f;
    color: #fff;
  }
`;
export const TransparentButton = styled.button`
  border: none;
  box-shadow: none;
  height: 50px;

  border-radius: 100px;
  padding: 12px 32px;
  background: #fff;

  transition: all 0.3s;

  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #424242;

  &:hover {
    background: none;
    color: none;
  }
  i {
    font-size: 18px;
  }
`;

export const CardStyles = styled(Card)`
  box-shadow: 0px 0.4vmin 2vmin rgba(66, 66, 66, 0.1);
  border-radius: 1vmin 1vmin 1vmin 1vmin;
  border: none;

  .card-footer {
    border: none;
    background-color: #fff;
  }
`;

export const TotalValueCardStyles = styled(CardStyles)`
  top: 12vmin;
  position: sticky;
`;

export const BlackCardStyles = styled(Card)`
  box-shadow: 0px 0.4vmin 2vmin rgba(66, 66, 66, 0.1);
  border-radius: 1vmin 1vmin 1vmin 1vmin;
  border: none;
  background: #424242;
  .card-footer {
    border: none;
    background-color: #fff;
  }
  i {
    color: #72b81f;
  }
`;
export const WeOfferCard = styled(CardStyles)`
  height: 200px !important;

  .card-body {
    padding: 1rem 64px;
  }

  .card-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    height: auto;
    color: #424242;
  }

  .bg-color-offer {
    display: flex;
    padding: 6px 12px;
    background: rgba(114, 184, 31, 0.4);
    border-radius: 4px;
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    letter-spacing: 0.36px;
  }
  .user-account-ok {
    margin-top: 8px;
    color: #797979;
    font-size: 16px;
  }

  @media screen and (max-width: 600px) {
    height: 233px !important;

    img {
      width: 60px;
      height: 60px;
    }

    .card-body {
      padding: 23px;
    }

    .card-title {
      font-size: 18px;
      height: auto;
    }
    .bg-color-offer {
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.32px;
    }

    .user-account-ok {
      margin-top: 6px;
      font-size: 14px;
    }
  }

  @media screen and (min-width: 600px) and (max-width: 1210px) {
    height: 233px !important;

    img {
      width: 90px;
      height: 90px;
    }
    .card-body {
      padding: 23px;
    }
  }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .card-body {
      padding: 1rem !important;
    }
  }
`;
export const ContentCard = styled(CardStyles)`
  margin-bottom: 50px;
  .bi-alarm {
    font-size: 1.5rem;
  }

  .card-body {
    padding-bottom: 20px;
  }
  .card-img-top {
    max-width: 100%;
    height: 139px;
    object-fit: cover;
  }
  .carrousel.card-title {
    text-align: left;
    min-height: 48px;
  }

  .card-title {
    color: #424242;

    font-weight: 600;
    font-size: 20px;
    height: auto;
  }

  .card-text {
    text-align: left;
    min-height: 48px;
    font-weight: 400;
    font-size: 18px;

    line-height: 24px;
    letter-spacing: 0.36px;
    color: #424242;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .our-activities-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    height: 100%;
    text-transform: uppercase;
  }

  .our-activities-desc {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 32px;

    .card-img-top {
      max-width: auto;
      height: 139px;
      object-fit: cover;
    }

    .card-body {
      padding-bottom: 16px;
    }

    .card-title {
      font-size: 18px;
      line-height: 26px;
    }
    .card-text {
      font-size: 14px;
      line-height: 20px;
      min-height: auto;
    }

    .our-activities-desc {
      font-size: 1rem;
      line-height: 20px;
    }
  }

  @media screen and (min-width: 601px) and (max-width: 1210px) {
    margin-bottom: 32px;

    .card-img-top {
      max-width: auto;
      height: 220px;
      object-fit: cover;
    }
    .card-title {
      font-size: 20px;
      line-height: 26px;
    }
    .card-text {
      font-size: 16px;
      line-height: 20px;
      min-height: auto;
    }

    .card-body {
      padding-bottom: 20px;
    }

    .our-activities-desc {
      font-size: 18px;
      line-height: 23px;
    }
  }
`;

export const InputStyles = styled.input`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #424242;

  border: none;
  outline: unset;

  width: 100%;
  height: 70px;
  border-radius: 100px;
  padding: 24px;
  padding-right: 34px;

  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const TextAreaStyles = styled.textarea`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #424242;

  padding: 24px;
  padding-left: 34px;

  border: none;
  outline: unset;
  width: 100%;

  background: #fff;
  box-shadow: 0px 4px 20px rgba(66, 66, 66, 0.1);
  border-radius: 30px;
  border: 0;

  @media screen and (max-width: 600px) {
    padding: 20px;
  }
`;

export const SearchButton = styled.button`
  font-weight: 400;
  font-size: 1.8vmin;
  line-height: 2vmin;
  text-align: center;
  color: #fff;
  background: #000;
  border: none;
  color: #fff;
  border-radius: 100px;
  width: 25vmin;
  padding: 0.9vmin;
  position: absolute;
  right: 0;
  top: 0px;
  bottom: 0;
`;

export const RoundedImage = styled.img`
  border-radius: 0.3rem;
  object-fit: cover;
`;

export const InstitutionalInformationIconDiv = styled.div<{ color: string }>`
  color: ${(props) => props.color};
  width: 66px;
  height: 66px;
  background: #fff;
  box-shadow: 0px 4px 20px rgba(66, 66, 66, 0.1);
  border-radius: 10px;
  img {
    display: flex;
  }
  .bi-bullseye {
    font-size: 26px;
  }
  .bi-globe-americas {
    font-size: 26px;
  }
  .bi-whatsapp {
    font-size: 2.7vmin;
  }
  .bi-telephone {
    font-size: 2.7vmin;
  }
  .bi-at {
    font-size: 3.4vmin;
  }
  .bi-signpost-split {
    font-size: 3.4vmin;
  }
  .footer-img {
    width: 100%;
  }

  .iconBox {
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 26px !important;
    }
  }

  @media screen and (max-width: 1210px) {
    width: 48px;
    height: 48px;

    border-radius: 10px;

    .bi-bullseye {
      font-size: 3rem;
    }
    .bi-globe-americas {
      font-size: 5vmin;
    }
    .bi-whatsapp {
      font-size: 1.6rem;
    }
    .bi-telephone {
      font-size: 1.8rem;
    }
    .bi-at {
      font-size: 1.8rem;
    }
    .bi-signpost-split {
      font-size: 1.8rem;
    }
    .footer-img {
      width: auto;
    }

    .iconBox {
      i {
        font-size: 22px !important;
      }
    }
  }
`;

export const BaseModalStyles = styled(Modal)`
  .modal-body {
    padding-top: 0px;
    min-height: 20vmin;
  }
  .modal-dialog {
    min-width: 80vmin;
  }
  .modal-header {
    border: none;
    padding-bottom: 0px;
    .btn-close {
      font-size: 1vmin;
      box-shadow: none;
    }
  }
  .modal-content {
    width: 100%;
    padding: 0px;
  }
  h2 {
    color: #424242;

    font-size: 2.9vmin;

    font-weight: 600;
    line-height: 132.523%;
    margin: 0px;
  }
  h1 {
    color: #424242;

    font-size: 3.8vmin;

    font-weight: 600;
    line-height: 132.523%;
  }
  span {
    color: #424242;

    font-size: 2vmin;

    font-weight: 400;
    line-height: 132.523%;
  }
  @media screen and (max-width: 1210px) {
    .modal-body {
      padding-top: 0px;
      min-height: 20vmin;
    }
    .modal-dialog {
      min-width: 80vmin;
    }
    .modal-header {
      .btn-close {
        position: relative;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: none;

        margin-top: 0.3rem;
        font-size: 0.8rem;
      }
    }
    .modal-content {
      width: 100%;
      padding: 0px;
    }
    h2 {
      font-size: 1.5rem;
    }
    h1 {
      font-size: 1.9rem;
    }
    span {
      font-size: 1rem;
    }
  }
  @media screen and (max-width: 899px) {
    h2 {
      font-size: 1.5rem;
    }
    span {
      font-size: 1rem;
    }
    .modal-header {
      .btn-close {
        margin-left: auto;

        margin-right: none;
      }
    }
  }
`;

export const PartnerImg = styled.img`
  width: 300px; 
  height: auto; 
  display: block; 
`;

export const CarouselPartner = styled.div`
  width: 300px;
  height: auto;
  margin: 0 auto;
  overflow: hidden;
  position: relative;

  .carousel-images {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  .carousel-image {
    width: 300px;
    height: auto;
    display: block;
    object-fit: contain;
  }

  @media (max-width: 399px) {
    .carousel-image {
      width: 264px;
    }
  }
`;
